import { Grid } from '@material-ui/core';
import React from 'react';
import { BulletItem } from './layout/BulletItem';
import { Content } from './layout/Content';
import { colors } from "./theme";
import { Helmet } from 'react-helmet';
import { CityType } from "./types";
import { Fragment } from "react";
import { DifferentCityContent } from "./layout/DifferentCityContent";

import { Review, ReviewItem } from "./layout/Review";
import { CompanyReviewItem, CompanyReviews } from './reviews/company/CompanyReviews';


interface IDetiProps {
  city?: CityType;
}

    const imgZsStareMesto = require("./reviews/company/logo/zs_stare_mesto.png");
    const imgSiemens = require("./reviews/company/logo/siemens.png");
    
    const reviews: CompanyReviewItem[] = [
        { name: "zs_stare_mesto", author: "Petra", description: "Hra byla perfektní, zabavila děti skoro na 2 hodiny. Byl vidět zápal do luštění. Šifry byly opravdu na přemýšlení, sama jsem nad tím musela dlouho přemýšlet, ještěže jsem měla od Vás klíč :-D. Skupinky chodily vyčerpané, ale nadšené :-) Děkuju, že my pedagogové jsme měli chvili čas na vydechnutí. Počasí nám vyšlo, což je u této aktivity velký bonus - sednout si do stínu, na trávu, u kašny a luštit.", rating: 5, imageUrl: imgZsStareMesto },
        // { name: "siemens", author: "Nikola Stýbar", description: "Hru jsme si užili, opravdu se líbila. Děkujeme za hru i vždy příjemnou komunikaci.", rating: 5, imageUrl: imgSiemens }
    ]

export const Deti: React.FC<IDetiProps> = ({ city }) => {

    return (
        <main style={{ textAlign: "justify" }}>
            <Helmet>
                <title>Akce pro děti a studenty</title>
                <link rel="canonical" href={`https://www.zabavnamesta.cz/${city ? city.toLowerCase() + "/" : ""}deti`} />
            </Helmet>
            <Content background={colors.sections.section2.bgColor} >
                <h1>Akce pro děti a studenty</h1>
                <div>
                    <p>
                        <DifferentCityContent
                            currentCity={city}
                            shared={
                                <Fragment>
                                    Pokud z jakéhokoliv důvodu chcete zabavit skupinu dětí či studentů a myslíte,
                                     že by šifrovačka mohla být pro ně ta správná zábava a zpestření např. na školním výletě či skautské výpravě,
                                      stačí se nám ozvat.
                                </Fragment>
                            }
                            empty={
                                <Fragment>
                                    Pokud z jakéhokoliv důvodu chcete zabavit skupinu dětí či studentů a myslíte,
                                     že by šifrovačka mohla být pro ně ta správná zábava a zpestření např. na školním výletě či skautské výpravě,
                                      stačí se nám ozvat.
                                </Fragment>
                            }
                        />
                        {(city == "Ostrava" || city === "Brno" || city === "Velke-Losiny" || city === "Praha") && 
                            <Fragment>
                                &nbsp;Hry jsou vhodné pro děti/studenty od 12 let.
                            </Fragment>
                        }
                    </p>
                </div>
                <h2>Co nabízíme</h2>
                <Grid container>
                    <Grid item sm={12}>
                        <BulletItem><p>přizpůsobení existující hry vašim potřebám (např. její zkrácení či úpravy trasy, aby více vyhovovala nabitému programu např. školního výletu)</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>při objednání 4 a více herních balíčků dodáme i “učitelský” balíček (balíček zdarma, který pomůže učitelskému doprovodu seznámit se šiframi, které na děti čekají, balíček také obsahuje seznam nápověd, řešení a vyznačenou trasu šifrovačky)</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>vytvoření hry na míru, aby přesně seděla na vámi plánovanou aktivitu</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>hry s veškerou podporou (webová aplikace s nápovědami a řešeními) jsme schopní připravit i v angličtině</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>úprava designu šifrovačky – doplnění loga vaší školy, třídy apod.</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>můžeme pomoci s dohodutím občerstvení na trati</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>další úpravy dle vašich požadavků a našich možností</p></BulletItem>
                    </Grid>
                </Grid>

                <p>
                    Pokud vás to zaujalo, ozvěte se nám na <a href="mailto:info@zabavnamesta.cz">info@zabavnamesta.cz</a> popř. zavolejte na +420&nbsp;605&nbsp;557&nbsp;009 nebo
                    +420&nbsp;733&nbsp;395&nbsp;050.
                </p>

                <h2>Reference</h2>
                <CompanyReviews {...{ reviews }} />

            </Content>
        </main>
    )
}