import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";

import { Header, Footer } from "./layout";
import { theme } from "./theme";
import { Main } from "./Main";
import { Reklamace } from "./Reklamace";
import { Podminky } from "./Podminky";
import { OsobniUdaje } from "./OsobniUdaje";
import { Vysledky } from "./Vysledky";
import { BrowserRouter } from "react-router-dom";
import { Route, Switch } from "react-router";
import { colors } from "./theme";
import { CityType, ICityProps } from "./types";
import { Teambuilding } from "./Teambuilding";
import { Deti } from "./Deti";

const App: React.FC<Partial<ICityProps>> = ({ city }) => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Header changeColorOffset={1} changeColor={colors.header.appbar.bgColor2} city={city} showNavbar={!!city} />
        <Switch>
          <Route path="/reklamace" component={Reklamace} />
          <Route path="/obchodni-podminky" component={Podminky} />
          <Route path="/deti" component={() => <Deti city={city} />} />
          <Route path="/:city/deti" component={() => <Deti city={city} />} />
          <Route path="/teambuilding" component={Teambuilding} />
          <Route path="/ochrana-osobnich-udaju" component={OsobniUdaje} />
          {city && <Route path="/ostrava/vysledkova-listina" component={()=><Vysledky city={city}/>} />}
          {city && <Route path="/brno/vysledkova-listina" component={()=><Vysledky city={city}/>} />}
          {city && <Route path="/olomouc/vysledkova-listina" component={()=><Vysledky city={city}/>} />}
          {city && <Route path="/frydek-mistek/vysledkova-listina" component={()=><Vysledky city={city}/>} />}
          {city && <Route path="/velke-losiny/vysledkova-listina" component={()=><Vysledky city={city}/>} />}
          {city && <Route path="/praha/vysledkova-listina" component={()=><Vysledky city={city}/>} />}          
          <Route path="/" component={() => <Main city={city} />} />
        </Switch>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
