import { Grid } from "@material-ui/core";
import React from "react";
import { AnimatedMount, AnimationType } from "./layout/AnimatedMount";
import { ProductBox } from "./layout/ProductBox";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Ribbon } from "./layout/Ribbon";
import { colors } from "./theme";
import { getCityFriedlyName } from "./utils";
import { CityType } from "./types";
 
interface ICityProps {
  name: CityType;
  imageUrl: string;
  isNew?: boolean;
  comingSoon?: boolean;
  index: number;
}

export const City: React.FC<ICityProps> = ({ name, imageUrl, isNew, comingSoon, index }) => {
  const history = useHistory();
  const classes = useStyles({});
  const getAnimation = (index: number) => {
    if (index % 2 === 0) return AnimationType.slideInLeft;
    else return AnimationType.slideInRight;
  };
  const onClick = () => {
    history.push(`/${name}`);
  };
  return (
    <Grid item xs={12} sm={4} style={{ display: "flex" }} key={index}>
      <AnimatedMount onEnterAnimation={getAnimation(index)} duration={"1.3s"} display="flex">
        <a href={`/${name.toLowerCase()}`} className={classes.link}>
          <ProductBox 
          title={name} 
          content={<h1>{getCityFriedlyName(name)}</h1>} 
          imageUrl={imageUrl} 
          onClick={onClick} 
          ribbonComponent={
            (isNew && <Ribbon text={"Novinka"} bgColor={colors.game.ribbon.discount.bgColor} color={colors.game.ribbon.discount.color} />)
            || (comingSoon && <Ribbon text={"Připravujeme"} bgColor={colors.game.ribbon.comingSoon.bgColor} color={colors.game.ribbon.comingSoon.color} />)
          }
          />
        </a>
      </AnimatedMount>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
  },
}));
