import { createMuiTheme } from '@material-ui/core/styles';
import primary from '@material-ui/core/colors/green';
import secondary from '@material-ui/core/colors/blueGrey';

const h1bg = "#599722";
const main1 = "#28a745";
const main2 = "#3fcc5f";
// const section1 = "#d7ffe0";
// const section2 = "#eaeaea";
// const section3 = "#cecece";
// const section4 = "rgb(247, 229, 215)";
const section1 = "#f4f6f9";
const section2 = "#f4f6f9";
const section3 = "#f4f6f9";
const section4 = "#f4f6f9";

const btnColor = "white";

export const colors: any = {
    board:{
        places:{
            first: "goldenrod",
            second: "#8a8484",
            third: "#b75827",
        }
    },
    common:{
        fadedGreen: "#ccf1cc"
    },
    header: {
        contacts: {
            color:"black",
            hoverColor: "#333", 
        },
        appbar:{
            color:"black", 

            hoverColor: main1,
            bgColor:"white", 
            bgColor2:"green",
            titleColor: "white",
            titleBgColor: h1bg,
            subTitleColor: "white",
            subTitleBgColor: "rgba(0, 0, 0, 0.6)",

        },
        image: {
            bgColor: "white"
        }
        
    },
    sections:{
        section1: {
            bgColor: section1,
        },
        section2: {
            bgColor: section2,
        },
        section3: {
            bgColor: section3,
        },
        section4: {
            bgColor: section4,
        },
    },
    game:{
        homeDelivery:{
            bgColor: "#20c997",
            hoverColor: "#077756",
        },
        postDelivery:{
            bgColor: "#007bff",
            hoverColor: "#005abb",
        },
        hours:{
            bgColor: "#17a2b8",
            hoverColor: "#138fa2",
        },
        level:{
            easy: {
                bgColor: "#2e976b",
                hoverColor: "#1f6f4d",
                color: "white",
            },
            medium: {
                hoverColor: "#efa703",
                bgColor: "#dea703",
                color: "white",
            },
            hard: {
                bgColor: "#dc3545",
                hoverColor: "#b91e2d",
                color: "white",
            },
        },
        icon: {
            bgColor: main1,
            hoverColor: main2,
            color: "white",
        },
        ribbon:{
            preview:{
                bgColor: main1,
                color: "white", 

            },
            comingSoon:{
                bgColor: "#ffc107",
                color: "#1f2d3d", 

            },
            discount:{
                bgColor: "#28a745",
                color: "white", 

            },          
        },
    },
    bullet:{
        icon:{
            bgColor: main1,
            hoverColor: main2,
            color: "white",

        }
    },
    footer:{

        color: "#eee",
        bgColor: "black",
        link:{
            color: "white",
            hoverColor: "#eee", 

        },
        chevron:{
            bgColor: "white",
            ringColor: section3,
            hoverColor: main1,

        },
        copyright:{
            color: "#4c4c4c",
            bgColor: "black",
            icon:{
                bgColor : "gray",
            },
            link:{
                color: "#777",
                hoverColor: "#eee", 
    
            },
        }
    }
}

export const theme = createMuiTheme({
    palette: {
        primary: primary,
        secondary: secondary,
        text: {
            primary: "#666",
        },
        background: {
            //default: "rgb(37,37,37)"
        }
    },
    overrides: {
        MuiPaper: {
            root: {
                //background: "black"
            }
        },
        MuiToolbar: {
            // root:{
            //     background: "linear-gradient(to right, rgb(20, 136, 204), rgb(40, 116, 204));"

            // }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                //     borderColor: "#444444",
                //     backgroundColor:"#2d2d2d",
                //    // border: "1px solid wheat",
                //     color: "wheat",
            },
        },
        MuiButton: {
            root: {
                // backgroundColor: "rgb(88,151,0)",
                // background: "linear-gradient(41deg, rgba(88,151,0,1) 50%, rgba(124,205,43,1) 100%)",
                // border: 0,
                // color: 'white',
                // height: 48,
                // padding: '0 30px',
                // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            },
            containedPrimary: {
                backgroundColor: main1,
                color: btnColor,
                "&:hover":{
                    backgroundColor: main2,

                }
                // background: "linear-gradient(41deg, rgba(88,151,0,1) 50%, rgba(124,205,43,1) 100%)"
                // color: "white",
                // //background:"orange",
                // backgroundColor: "#2ecc71",
                // boxShadow: "0px 5px 0px 0px #15B358",
                // "&:hover":{
                //     backgroundColor: "#2ecc71",
                // boxShadow: "0px 5px 0px 0px #15B358",
                // }
            }
        },
        MuiTableRow: {
            hover: {
                "&:hover": {
                    backgroundColor: "rgba(33, 150, 243, 0.25) !important"
                }
            }
        }

    }

});