

import React from 'react';
import { Box, Card, CardContent } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../theme";
import Skeleton from 'react-loading-skeleton';

export class ReviewItem {
    public readonly CreatedBy: string | undefined = undefined;
    public readonly CreatedDate: string | undefined = undefined;
    public readonly GameId: number | undefined = undefined;
    public readonly Name: string | undefined = undefined;
    public readonly GameName: string | undefined = undefined;
    public readonly TextContent: string | undefined = undefined;
    public readonly TotalRating: number | undefined = undefined;
}

interface IReviewProps {

    isLoading?: boolean;
    title?: string;
    subtitle?: string;
    rating?: number;
    description?: string;
    imageUrl?: string;
}


export const Review: React.FC<IReviewProps> = ({ title, subtitle, rating, description, isLoading = false, imageUrl }) => {
    const classes = useStyles({});
    const renderTitle = (title?: string, imageUrl?: string)=> imageUrl ? <img className={classes.image} src={imageUrl} alt="review-logo"/> : title;

    return (

        <div className={classes.root}  >

            <Card className={classes.main} raised={true} >
                <CardContent className={classes.content}>
                    <h5 className={classes.title} > {isLoading ? <Skeleton height={40} /> : renderTitle(title, imageUrl)}</h5>
                    <h6 className={classes.subTitle} > {isLoading ? <Skeleton height={40} /> : subtitle}</h6>
                    {isLoading ?
                        <div style={{ marginBottom: 20 }}><Skeleton count={1} /></div>
                        :
                        <Box justifyContent="center" justifyItems="center" display="flex" style={{ marginBottom: 10 }}>
                            <Rating value={rating} precision={0.5} size="small" readOnly={true} />
                        </Box>

                    }
                    {isLoading ?
                        <div style={{ marginBottom: 20 }}><Skeleton count={3} /></div>
                        :
                        description
                    }

                </CardContent>
            </Card>
        </div>
    )
}
const useStyles = makeStyles(() => ({
    root: {
        textAlign: "left",
        fontSize: "1em",
        padding: 10,
        display: "flex",
        position: "relative",
        marginTop: 10,
        height: "100%",

    },
    image:{
        height: 60,
    },
    icon: {
        height: 30,
    },
    skeleton: {
        flexGrow: 1,
    },
    skeletonIcon: {
        width: "20px",
        flexGrow: 0,
        marginLeft: "0px !important",
    },
    line: {
        marginBottom: 10,
        display: "flex",
        "& svg": {
            display: "block",
            minWidth: 24,
        },
        "& p": {
            display: "block",
            marginLeft: 10,
            marginTop: 2,
            marginBottom: 0,
        }
    },
    main: {
        height: "100%",
        flexGrow: 1,
    },
    inner: {
        margin: "0px 15px",
    },
    media: {
        display: "flex",
        justifyContent: "center",
    },
    mediaBg: {
        height: 100,

    },
    circle: {
        padding: 15,
        width: "100%",
        height: "100%",
        // height: circleSize,
        // width: circleSize,
        // borderRadius: circleSize / 2,
        // background: colors.game.icon.bgColor,
        // position: "absolute",
        // left: 40,
        // top: -30,

    },
    city: {
        fontSize: "16px",
        marginBottom: 3,
    },
    locationIcon: {
        verticalAlign: "middle",
        fontSize: 19,
        color: "black",

    },
    preview: {

        position: "absolute",
        right: "-5px; top: -5px",
        zIndex: 1,
        overflow: "hidden",
        width: "75px; height: 75px",
        textAlign: "right",

        "& span": {
            fontSize: 10,
            fontWeight: "bold",
            color: "#FFF",
            textTransform: "uppercase",
            textAlign: "center",
            lineHeight: 20,
            transform: "rotate(45deg)",

            width: 100,
            display: "block",
            // background: "linear-gradient(#F70505 0%, #8F0808 100%)",
            background: colors.bullet.icon.bgColor,
            boxShadow: "0 3px 10px -5px rgba(0, 0, 0, 1)",
            position: "absolute",
            top: 19,
            right: -21,
        }
    },
    title: {
        fontSize: "16px !important",
        lineHeight: "18px  !important",
        paddingBottom: 5,
        textAlign: "center",
        margin: 0,

    },
    subTitle: {
        fontSize: "12px !important",
        lineHeight: "14px  !important",
        paddingBottom: 10,
        textAlign: "center",
        margin: 0,

    },
    content: {
        fontSize: "0.8em",
    },
    actionArea: {
        position: "absolute",
        bottom: 25,
        textAlign: "center",
        display: "flex",
        left: 25,
        right: 25,
        justifyContent: "center",
    },
    price: {
        flexGrow: 1,
        fontSize: "1.7em",
        width: "100%",
        textAlign: "left",
    },
    buy: {
        "& > button": {
            marginLeft: 5,
        }
    },
    detailButton: {
        backgroundColor: "orange",
        "&:hover": {
            backgroundColor: "darkorange",

        }
    }

}));