import React from 'react';
import { Box, Grid, Container } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import * as Scroll from "react-scroll";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MailIcon from '@material-ui/icons/Mail';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import { colors } from "../theme";

export const Footer: React.FC<{}> = () => {
    const classes = useStyles({});
    const currentYear = new Date().getFullYear();

    return (
        <div className={classes.root}>
            <Container>
                <div className={classes.topOuter}>
                    <div className={classes.topInner} onClick={() => Scroll.animateScroll.scrollToTop()}>
                        <KeyboardArrowUpIcon className={classes.chevron} />
                    </div>
                </div>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={7}>
                        <h5 className={classes.title}><span>Zábavná města</span></h5>
                        <p className="pb-1">
                            Zábavné odpoledne pro celou rodinu. Dobu startu si volíte sami dle svého uvážení. Nejste omezeni nutností si rezervovat konkrétní termín.
                            Obálku s šiframi si vyzvednete osobně před startem hry nebo si ji necháte zaslat poštou.
                    </p>

                        <Box display="flex">
                        <Box flexGrow={1}>
                            <a href="https://www.kudyznudy.cz/aktivity/venkovni-unikove-hry-v-olomouci?utm_source=kzn&utm_medium=partneri_kzn&utm_campaign=banner">
                                <img className={classes.smallLogo} height="20" src="/img/footer/kudyznudy_logo.png" title="Kudy z nudy logo" alt="logo kudy z nudy" />
                            </a>
                            </Box>
                            <Box flexGrow={1}><img className={classes.smallLogo} height="20" src="/img/footer/logo_barevne.png" title="ThePay CZ" alt="logo the pay" /></Box>
                            <Box flexGrow={1}><img className={classes.smallLogo} height="20" src="/img/footer/12355_sc_62x34.gif" title="MasterCard" alt="logo mastercard" /></Box>
                            <Box flexGrow={1}><img className={classes.smallLogo} height="20" src="/img/footer/Verified_by_Visa.png" title="VISA" alt="logo visa" /></Box>
                            <Box flexGrow={1}><img className={classes.smallLogo} height="20" src="/img/footer/le-logo-wide.png" title="Let's Encrypt" alt="logo lets encrypt" /></Box>
                        </Box>
                        <h5 className={classes.title}><span>Otevírací doba</span></h5>
                        <p>Pondělí - Pátek: 9.00 - 17.00 hod (na telefonu)</p>
                    </Grid>
                    <Grid item sm={6} md={2} >
                        <h5 className={classes.title}><span>Odkazy</span></h5>
                        <a href="/obchodni-podminky">Obchodní podmínky</a><br />
                        <a href="reklamace">Reklamační řád</a><br />
                        <a href="/ochrana-osobnich-udaju">Ochrana osobních údajů GDPR</a><br />
                        <a href="/download/formular-odstoupeni-od-smlouvy.pdf" target="_blank" rel="noopener noreferrer">Formulář pro odstoupení od smlouvy</a><br />
                        <br />

                    </Grid>
                    <Grid item sm={6} md={3}>
                        <h5 className={classes.title}><span>Provozovatel</span></h5>
                        <address>
                            <strong>ZÁBAVNÁ MĚSTA s.r.o.</strong><br />
                        Bělkovice - Lašťany 686, 783 16<br />
                        Česká Republika<br />
                        IČO 089 69 817<br />
                        DIČ CZ08969817
                    </address>
                    </Grid>
                </Grid>



            </Container>
            <Box display="flex" className={classes.social} justifyContent="center" flexWrap="wrap">

                <Box display="flex" flexDirection="column" className={classes.socialLink} justifyContent="center" justifyItems="center" alignItems="center">
                    <PhoneIcon className={classes.phoneIcon} />
                    <span>+420 605 557 009</span>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.socialLink} justifyContent="center" justifyItems="center" alignItems="center">
                    <MailIcon className={classes.phoneIcon} />
                    <a href="mailto:info@zabavnamesta.cz">info@zabavnamesta.cz</a>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.socialLink} justifyContent="center" justifyItems="center" alignItems="center">
                    <FacebookIcon className={classes.phoneIcon} />
                    <a href="https://www.facebook.com/Z%C3%A1bavn%C3%A1-m%C4%9Bsta-110476003906564/">Náš facebook</a>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.socialLink} justifyContent="center" justifyItems="center" alignItems="center">
                    <InstagramIcon className={classes.phoneIcon} />
                    <a href="https://www.instagram.com/zabavnamesta">Náš instagram</a>
                </Box>
                {/* <Box display="flex" flexDirection="column" className={classes.socialLink} justifyContent="center" justifyItems="center" alignItems="center">
                    
                    <a href="https://www.kudyznudy.cz/?utm_source=kzn&utm_medium=partneri_kzn&utm_campaign=banner"><KudyZNudyIcon height={40} /></a>
                </Box> */}
            </Box>
            <div className={classes.copyright}>
                ® 2020 - {currentYear} | ZabavnaMesta.cz | Všechna práva vyhrazena. 
                <span>* Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></span> 
                <span> and  <a href="https://fontawesome.com/" title="Freepik">Font Awesome</a> </span>
            </div>
        </div>
    )
}


const useStyles = makeStyles(() => ({
    root: {
        marginTop: 25,
        zIndex: 10,
        background: colors.footer.bgColor,
        position: "relative",
        fontSize: "1.0em",
        color: colors.footer.color,
        fontFamily: "SourceSansPro-light",
        "& a": {
            color: colors.footer.link.color,
            textDecoration: "none",
        },
        "& a:hover": {
            textDecoration: "underline",
            color: colors.footer.link.hoverColor,
        }
    },
    social: {
        color: "#666",
        marginTop:30,
    },
    socialLink: {
        margin: "0 40px",
        width: 100,
        "& span":{
            color: "white"
        }
    },
    smallLogo: {

    },

    world: {

        position: "absolute",
        height: 140,
        bottom: 0,
    },
    title: {
        marginBottom: 15,
        "& span": {
            borderBottom: "1px dotted white",
            fontSize: "1.5em",
        }
    },
    topInner: {
        borderRadius: "20px",
        color: colors.footer.bgColor,
        backgroundColor: colors.footer.chevron.bgColor,
        height: "40px",
        width: "40px",
        position: "relative",
        paddingLeft: "9px",
        lineHeight: "40px",
        borderColor: "rgba(66, 69, 66, 0.7)",
        borderWidth: "1px",
        borderStyle: "solid",
        cursor: "pointer",



        "&:hover": {
            backgroundColor: colors.footer.chevron.hoverColor,
            cursor: "pointer",
        },
    },

    topOuter: {
        borderRadius: "25px",
        color: "rgb(66, 69, 66)",
        backgroundColor: colors.footer.chevron.ringColor,
        height: "50px",
        width: "50px",
        position: "relative",
        left: "50%",
        marginLeft: "-25px",
        marginTop: "-25px",
        top: "-25px",
        paddingLeft: "5px",
        paddingTop: "5px",

        i: {
            fontSize: "20px",
        }
    },

    chevron: {
        fontSize: "34px",
        marginLeft: "-7px",
        marginTop: "3px",
    },
    copyright: {
        fontFamily: "Arial",
        textAlign: "center",
        color: colors.footer.copyright.color,
        lineHeight: "28px",
        width: "100%",
        backgroundColor: colors.footer.copyright.bgColor,
        fontSize: "1em",
        marginTop: 30,

        "& a": {
            color: colors.footer.copyright.link.color,
            textDecoration: "none",
        },
        "& a:hover": {
            color: colors.footer.copyright.link.hoverColor,
            textDecoration: "none",
        }
    },
    mailIcon: {
        verticalAlign: "middle",
        marginRight: "15px",
        fontSize: 50,
        padding: 5,
    
    },
    phoneIcon: {
        marginRight: "10px",
        padding: 5,
        //backgroundColor: colors.footer.copyright.icon.bgColor,
        verticalAlign: "middle",
        fontSize: 50,
      

    },

}));